
import { computed, defineComponent, PropType } from 'vue'
import { ChartsDef, VCStats, VCStatsPerPeriod } from '@/modules/slowpoke/model/stats.model'
import { statsToChartsData } from '@/modules/slowpoke/utils/stats'
import ChartsGrid from '@/components/UI/ChartsGrid.vue'

export default defineComponent({
  name: 'SlowpokeTTSUsageStatisticsPeriodicCharts',
  components: { ChartsGrid },
  props: {
    stats: {
      type: Object as PropType<VCStatsPerPeriod>,
      required: true,
    },
  },
  setup(props) {
    const chartsDef: ChartsDef<VCStats> = [
      ['Number of requests', 'requests'],
      ['Input audio length (seconds)', 'inputAudioLength', (v) => (v / 1000).toFixed(1)],
      ['Input audio size (MB)', 'inputAudioSize', (v) => (v / 1024 / 1024).toFixed(2)],
      ['Output audio length (seconds)', 'outputAudioLength', (v) => (v / 1000).toFixed(1)],
      ['Output audio size (MB)', 'outputAudioSize', (v) => (v / 1024 / 1024).toFixed(2)],
    ]

    return {
      charts: computed(() => statsToChartsData(chartsDef, props.stats)),
    }
  },
})
