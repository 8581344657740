export function dateDiffInSeconds(a: Date, b: Date): number {
  return Math.abs(a.getTime() - b.getTime()) / 1000
}

// returns string in "3h, 11m, 13s" format
export function formatSecondsDuration(inputSeconds: number): string {
  const hours = Math.floor(inputSeconds / 3600)
  const minutes = Math.floor((inputSeconds - hours * 3600) / 60)
  const seconds = Math.round(inputSeconds - hours * 3600 - minutes * 60)

  return `${hours ? hours + 'h, ' : ''}${minutes ? minutes + 'm, ' : ''}${seconds}s`
}

export function datePlusDays(date: Date, days: number): Date {
  const d = new Date(date)
  d.setDate(d.getDate() + days)

  return d
}

export function dateToString(date: Date): string {
  const month = (date.getMonth() + 1).toString().padStart(2, '0')
  const day = date.getDate().toString().padStart(2, '0')

  return `${date.getFullYear()}-${month}-${day}`
}
