import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, normalizeStyle as _normalizeStyle, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-939633d2"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "sum" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Bar = _resolveComponent("Bar")!

  return (_openBlock(), _createElementBlock("div", {
    class: "charts",
    style: _normalizeStyle({ gridTemplateColumns: _ctx.gridTemplateColumns }),
    key: _ctx.rerenderKey
  }, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.charts, (chart, i) => {
      return (_openBlock(), _createElementBlock("div", { key: i }, [
        _createVNode(_component_Bar, {
          id: 'chart-' + i,
          options: chart.options,
          data: chart.data,
          style: _normalizeStyle(_ctx.chartStyles)
        }, null, 8, ["id", "options", "data", "style"]),
        _createElementVNode("div", _hoisted_1, [
          _createElementVNode("ul", null, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(chart.data.datasets, (dataset) => {
              return (_openBlock(), _createElementBlock("li", {
                key: dataset.label
              }, [
                _createElementVNode("b", null, "Total " + _toDisplayString(dataset.label.toLowerCase()) + ":", 1),
                _createTextVNode(" " + _toDisplayString(_ctx.sum(dataset.data)), 1)
              ]))
            }), 128))
          ])
        ])
      ]))
    }), 128))
  ], 4))
}