import { ChartsDef, PeriodicStats, StatsDateRange, StatsPeriod } from '@/modules/slowpoke/model/stats.model'
import { datePlusDays, dateToString } from '@/utils/time'
import { StatsTimeRangeI } from '@/components/StatsTimeRange.vue'

export function statsToChartsData<T extends { [key: string]: number }>(
  chartsDef: ChartsDef<T>,
  stats: PeriodicStats<T>
) {
  return chartsDef.map(([label, key, formatFn]) => {
    const sortedEntries = Object.entries(stats).sort((a, b) => {
      const [aYear, aMonth, aDay] = a[0].split('-')
      const [bYear, bMonth, bDay] = b[0].split('-')

      // compare years
      if (Number(aYear) < Number(bYear)) return -1
      if (Number(aYear) > Number(bYear)) return 1

      // if years are equal, compare months
      if (Number(aMonth) < Number(bMonth)) return -1
      if (Number(aMonth) > Number(bMonth)) return 1

      // if years and months are equal, compare days
      return Number(aDay) - Number(bDay)
    })

    const labels = []
    const data = []

    for (const [label, pStats] of sortedEntries) {
      labels.push(label)

      if (formatFn) {
        data.push(formatFn(pStats[key]))
      } else {
        data.push(pStats[key])
      }
    }

    return {
      data: {
        labels,
        datasets: [
          {
            label,
            data,
          },
        ],
      },
      options: {
        responsive: false,
      },
    }
  })
}

export function getDefaultDateRange(period: StatsPeriod): StatsDateRange {
  let showPastDays = 0

  switch (period) {
    case 'day':
      showPastDays = -7
      break
    case 'month':
      showPastDays = -600
      break
  }

  return { start: datePlusDays(new Date(), showPastDays), end: new Date() }
}

export function timeRangeToQueryParams(timeRange: StatsTimeRangeI, params?: URLSearchParams): URLSearchParams {
  if (!params) {
    params = new URLSearchParams()
  }

  if (timeRange.period) {
    params.set('period', timeRange.period)
  }

  if (timeRange.dateRange) {
    if (timeRange.dateRange.start) {
      params.set('dateFrom', dateToString(timeRange.dateRange.start))
    }

    if (timeRange.dateRange.end) {
      params.set('dateTo', dateToString(timeRange.dateRange.end))
    }
  }

  return params
}

export function getExtendedDefaultDateRange(): StatsTimeRangeI {
  return { dateRange: { start: new Date(2023, 0, 1), end: new Date() } }
}
