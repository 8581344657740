import { getAuthHeaders, getRequestIdHeaders, simpleAuthorizedQuery } from '@/utils/fetch'
import axios from 'axios'
import { apiErrorCatcher } from '@/api/_shared'
import { slowpokeApiUrl } from '@/modules/slowpoke/api/_shared'
import { APIKey } from '@/modules/slowpoke/model/api-keys.model'
import { StatsSubjectType, StatsType, TTSStatsPerPeriod } from '@/modules/slowpoke/model/stats.model'
import { timeRangeToQueryParams } from '@/modules/slowpoke/utils/stats'
import { StatsTimeRangeI } from '@/components/StatsTimeRange.vue'

export async function getAPIKey(id: number): Promise<APIKey> {
  const res = await simpleAuthorizedQuery<APIKey>(slowpokeApiUrl(`admin/api-keys/${id}?full=true`))

  return new APIKey(res)
}

export function getPeriodicStats(
  statsType: StatsType,
  subjectType: StatsSubjectType,
  subjectId: number | undefined,
  timeRange: StatsTimeRangeI
): Promise<TTSStatsPerPeriod> {
  let subjectSegment = ''

  switch (subjectType) {
    case 'apiKey':
      subjectSegment = `api-keys/${subjectId}/`
      break
    case 'client':
      subjectSegment = `clients/${subjectId}/`
      break
    default:
      subjectSegment = ''
  }

  const params = timeRangeToQueryParams(timeRange)

  return simpleAuthorizedQuery<TTSStatsPerPeriod>(
    slowpokeApiUrl(`admin/${statsType}/${subjectSegment}stats?${params.toString()}`)
  )
}

export async function generateClientAPIKey(clientID: number): Promise<APIKey> {
  return axios
    .post(
      slowpokeApiUrl(`admin/clients/${clientID}/api-keys`),
      {},
      {
        headers: { ...getRequestIdHeaders(), ...getAuthHeaders() },
      }
    )
    .catch(apiErrorCatcher)
    .then((r) => r.data)
    .then((data) => new APIKey(data))
}

export async function deleteAPIKey(APIKeyID: number): Promise<unknown> {
  return axios
    .delete(slowpokeApiUrl(`admin/api-keys/${APIKeyID}`), {
      headers: { ...getRequestIdHeaders(), ...getAuthHeaders() },
    })
    .catch(apiErrorCatcher)
}
