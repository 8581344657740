
import { computed, defineComponent, PropType, reactive, watch } from 'vue'
import { StatsSubjectType, StatsType, TTSStatsPerPeriod, VCStatsPerPeriod } from '@/modules/slowpoke/model/stats.model'
import DataLoader from '@/components/UI/DataLoader.vue'
import DataLoadingError from '@/components/UI/DataLoadingError.vue'
import UsageTTSStatisticsPeriodicCharts from '@/modules/slowpoke/components/stats/TTSUsageStatisticsPeriodicCharts.vue'
import UsageVCStatisticsPeriodicCharts from '@/modules/slowpoke/components/stats/VCUsageStatisticsPeriodicCharts.vue'
import { errored, isLoaded, isLoading, loaded, LOADING_STATE, LoadingState } from '@/utils/loading-state'
import { getPeriodicStats } from '@/modules/slowpoke/api/api-keys.api'
import { getDefaultDateRange } from '@/modules/slowpoke/utils/stats'
import StatsTimeRange, { DEFAULT_STATS_PERIOD, StatsTimeRangeI } from '@/components/StatsTimeRange.vue'

export default defineComponent({
  name: 'SlowpokePeriodicStats',
  components: {
    StatsTimeRange,
    UsageTTSStatisticsPeriodicCharts,
    UsageVCStatisticsPeriodicCharts,
    DataLoadingError,
    DataLoader,
  },
  props: {
    type: {
      type: String as PropType<StatsType>,
      required: true,
    },
    subjectType: {
      type: String as PropType<StatsSubjectType>,
      required: true,
    },
    subjectId: {
      type: Number,
    },
  },
  setup(props) {
    const data = reactive({
      ttsStats: LOADING_STATE as LoadingState<TTSStatsPerPeriod>,
      vcStats: LOADING_STATE as LoadingState<VCStatsPerPeriod>,
      timeRange: {
        period: DEFAULT_STATS_PERIOD,
        dateRange: getDefaultDateRange(DEFAULT_STATS_PERIOD),
      } as StatsTimeRangeI,
    })

    type statsKeyT = 'ttsStats' | 'vcStats'
    const statsKey = computed(() => (props.type + 'Stats') as statsKeyT)

    const loadStatistics = async () => {
      try {
        data[statsKey.value] = LOADING_STATE
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        data[statsKey.value] = loaded(
          await getPeriodicStats(props.type, props.subjectType, props.subjectId, data.timeRange)
        )
      } catch (e) {
        data[statsKey.value] = errored(e)
      }
    }

    watch(
      () => data.timeRange,
      () => loadStatistics()
    )

    loadStatistics()

    return {
      data,
      isLoading,
      isLoaded,
      statsKey,
    }
  },
})
