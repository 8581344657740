
import { computed, defineComponent, PropType, ref, watch } from 'vue'
import { Bar, ChartProps } from 'vue-chartjs'
import { Chart as ChartJS, Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale } from 'chart.js'
import useGlobalStore from '@/store/global-store'

ChartJS.register(Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale)

export default defineComponent({
  name: 'ChartsGrid',
  components: { Bar },
  props: {
    charts: {
      type: Array as PropType<ChartProps[]>,
      required: true,
    },
  },
  setup() {
    const globalStore = useGlobalStore()

    // forces charts rerender when grid size changes
    const rerenderKey = ref(0)
    watch(globalStore.config, () => {
      rerenderKey.value += 1
    })

    const sum = (arr: number[]) => {
      return arr.reduce((acc, a) => acc + Number(a), 0).toFixed(2)
    }

    const gridTemplateColumns = computed(() => {
      switch (Number(globalStore.config.statsGridSize)) {
        case 2:
          return '1fr 1fr 1fr'
        case 3:
          return '1fr 1fr'
        case 4:
          return '1fr'
        default:
          return '1fr 1fr 1fr 1fr'
      }
    })

    return {
      chartStyles: {
        position: 'relative',
        width: '100%',
        height: '200px',
        border: '1px solid var(--va-athens-gray)',
      },
      sum,
      gridTemplateColumns,
      rerenderKey,
    }
  },
})
