import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-0f124587"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "controls mb-4" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_va_select = _resolveComponent("va-select")!
  const _component_va_date_input = _resolveComponent("va-date-input")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (!_ctx.disablePeriod)
      ? (_openBlock(), _createBlock(_component_va_select, {
          key: 0,
          bordered: "",
          label: "Statistics time dimension",
          "model-value": _ctx.statsPeriodToOption(_ctx.modelValue.period || 'day'),
          "onUpdate:modelValue": _ctx.updatePeriod,
          options: [
        { text: 'Daily', value: 'day' },
        { text: 'Monthly', value: 'month' },
      ]
        }, null, 8, ["model-value", "onUpdate:modelValue"]))
      : _createCommentVNode("", true),
    _createVNode(_component_va_date_input, {
      label: "Time range",
      mode: "range",
      "model-value": _ctx.modelValue.dateRange,
      "onUpdate:modelValue": _ctx.updateRange
    }, null, 8, ["model-value", "onUpdate:modelValue"])
  ]))
}