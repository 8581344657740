import { isEmail } from '@/utils/validators'
import { APIKey } from '@/modules/slowpoke/model/api-keys.model'

export class Client {
  id?: number
  key: string
  email: string
  name: string
  balance: number
  apiKeys?: APIKey[]
  specialFeatures: string[]
  createdAt: Date
  updatedAt: Date

  constructor(doc: Partial<Client>) {
    this.id = doc.id || undefined
    this.key = doc.key || ''
    this.email = doc.email || ''
    this.name = doc.name || ''
    this.balance = doc.balance || 0
    this.apiKeys = Array.isArray(doc.apiKeys) ? doc.apiKeys.map((v) => new APIKey(v)) : undefined
    this.specialFeatures = Array.isArray(doc.specialFeatures) ? doc.specialFeatures : []
    this.updatedAt = doc.updatedAt ? new Date(doc.updatedAt) : new Date()
    this.createdAt = doc.createdAt ? new Date(doc.createdAt) : new Date()
  }
}

export function validateClient(client: Client): true | string[] {
  const errors: string[] = []

  if (!client.name) {
    errors.push('Name is required.')
  }

  if (!client.email) {
    errors.push('Email is required.')
  }

  if (!isEmail(client.email)) {
    errors.push('Email is invalid.')
  }

  return errors.length > 0 ? errors : true
}
