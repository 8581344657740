
import { defineComponent, PropType } from 'vue'
import { StatsDateRange, StatsPeriod } from '@/modules/slowpoke/model/stats.model'

export interface StatsTimeRangeI {
  period?: StatsPeriod
  dateRange: StatsDateRange
}

export interface PeriodOption {
  text: string
  value: StatsPeriod
}

export const DEFAULT_STATS_PERIOD = 'day'

export default defineComponent({
  name: 'StatsTimeRange',
  components: {},
  props: {
    modelValue: {
      type: Object as PropType<StatsTimeRangeI>,
      required: true,
    },
    disablePeriod: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  emits: ['update:modelValue'],
  setup(props, { emit }) {
    const statsPeriodToOption = (period: StatsPeriod): PeriodOption => {
      switch (period) {
        case 'day':
          return { text: 'Daily', value: period }
        case 'month':
          return { text: 'Monthly', value: period }
        default:
          throw new Error('Unknown period.')
      }
    }

    const updatePeriod = (option: PeriodOption) => {
      emit('update:modelValue', {
        ...props.modelValue,
        period: option.value,
      })
    }

    const updateRange = (value: StatsDateRange) => {
      emit('update:modelValue', {
        ...props.modelValue,
        dateRange: value,
      })
    }

    return { statsPeriodToOption, updatePeriod, updateRange }
  },
})
