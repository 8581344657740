import { getAuthHeaders, getRequestIdHeaders, simpleAuthorizedQuery } from '@/utils/fetch'
import axios from 'axios'
import { apiErrorCatcher } from '@/api/_shared'
import { slowpokeApiUrl } from '@/modules/slowpoke/api/_shared'
import { Client } from '@/modules/slowpoke/model/clients.model'
import { SlowpokeAccountsStats } from '@/modules/slowpoke/model/stats.model'
import { StatsTimeRangeI } from '@/components/StatsTimeRange.vue'
import { timeRangeToQueryParams } from '@/modules/slowpoke/utils/stats'

export async function getSpecialFeatures(): Promise<string[]> {
  return simpleAuthorizedQuery<string[]>(slowpokeApiUrl(`admin/clients/special-features`))
}

export async function getClient(id: number): Promise<Client> {
  const res = await simpleAuthorizedQuery<Client>(slowpokeApiUrl(`admin/clients/${id}`))

  return new Client(res)
}

export async function getAllClients(): Promise<Client[]> {
  const res = await simpleAuthorizedQuery<Client[]>(slowpokeApiUrl('admin/clients'))

  return (res || []).map((v) => new Client(v))
}

export async function updateClient(client: Client): Promise<Client> {
  const patch: Partial<Client> = {
    ...client,
  }

  return axios
    .patch(slowpokeApiUrl(`admin/clients/${client.id}`), patch, {
      headers: { ...getRequestIdHeaders(), ...getAuthHeaders() },
    })
    .catch(apiErrorCatcher)
    .then((r) => r.data)
    .then((data) => new Client(data))
}

export async function deleteClient(client: Client): Promise<unknown> {
  return axios
    .delete(slowpokeApiUrl(`admin/clients/${client.id}`), {
      headers: { ...getRequestIdHeaders(), ...getAuthHeaders() },
    })
    .catch(apiErrorCatcher)
}

export async function getAccountsStats(timeRange: StatsTimeRangeI): Promise<SlowpokeAccountsStats> {
  const params = timeRangeToQueryParams(timeRange)

  return simpleAuthorizedQuery<SlowpokeAccountsStats>(slowpokeApiUrl(`admin/clients/stats?${params.toString()}`))
}
