import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_StatsTimeRange = _resolveComponent("StatsTimeRange")!
  const _component_DataLoader = _resolveComponent("DataLoader")!
  const _component_DataLoadingError = _resolveComponent("DataLoadingError")!
  const _component_UsageTTSStatisticsPeriodicCharts = _resolveComponent("UsageTTSStatisticsPeriodicCharts")!
  const _component_UsageVCStatisticsPeriodicCharts = _resolveComponent("UsageVCStatisticsPeriodicCharts")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_StatsTimeRange, {
      modelValue: _ctx.data.timeRange,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.data.timeRange) = $event))
    }, null, 8, ["modelValue"]),
    _createVNode(_component_DataLoader, {
      "is-loading": _ctx.isLoading(_ctx.data[_ctx.statsKey])
    }, null, 8, ["is-loading"]),
    _createVNode(_component_DataLoadingError, {
      subject: _ctx.data[_ctx.statsKey]
    }, null, 8, ["subject"]),
    (_ctx.type === 'tts' && _ctx.isLoaded(_ctx.data.ttsStats))
      ? (_openBlock(), _createBlock(_component_UsageTTSStatisticsPeriodicCharts, {
          key: 0,
          stats: _ctx.data.ttsStats.data
        }, null, 8, ["stats"]))
      : _createCommentVNode("", true),
    (_ctx.type === 'vc' && _ctx.isLoaded(_ctx.data.vcStats))
      ? (_openBlock(), _createBlock(_component_UsageVCStatisticsPeriodicCharts, {
          key: 1,
          stats: _ctx.data.vcStats.data
        }, null, 8, ["stats"]))
      : _createCommentVNode("", true)
  ], 64))
}