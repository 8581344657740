export class APIKey {
  id?: number
  clientId?: number
  apiKey: string
  createdAt: Date
  updatedAt: Date

  constructor(doc: Partial<APIKey>) {
    this.id = doc.id || undefined
    this.clientId = doc.clientId || undefined
    this.apiKey = doc.apiKey || ''
    this.updatedAt = doc.updatedAt ? new Date(doc.updatedAt) : new Date()
    this.createdAt = doc.createdAt ? new Date(doc.createdAt) : new Date()
  }
}
